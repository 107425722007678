<template>
  <div id="main">
    <el-tabs type="border-card" id="main-tabs">
      <el-tab-pane label="功能">
        <el-switch v-model="glow" size="large" inactive-text="透视" @change="glow_main()" />
      </el-tab-pane>
      <el-tab-pane label="关于">
        <p>稳定单透，新手作品，官匹稳定，平台自测</p>
        <p>QQ: 3361096694</p>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: 'GlowIndex',
  data() {
    return {
      glow: false
    }
  },
  mounted() {
    this.glow_get_status()
  },
  methods: {
    glow_main() {
      if (!this.glow) {
        this.$axios.get('http://127.0.0.1:7184/glow/off')
      } else {
        this.$axios.get('http://127.0.0.1:7184/glow/on')
      }
    },
    glow_get_status() {
      this.$axios.get('http://127.0.0.1:7184/status').then(res => {
        this.glow = res.data.status
      })
    }
  }
}
</script>

<style>
#main {
  height: 300px;
  width: 450px;
  margin: 0px auto;
}

#main-tabs {
  height: 300px;
  width: 450px;
}
</style>