<template>
  <GlowIndex></GlowIndex>
</template>

<script>
import GlowIndex from './components/GlowIndex.vue'

export default {
  name: 'App',
  components: {
    GlowIndex
  }
}
</script>
